"use client";

import { PlatformsIcon } from "~/components/non-editable/core";
import styles from "./lead-capture-form.module.scss";
import { leadCaptureFormProps } from "./props";
import { useForm } from "react-hook-form";
import {
  maskPhone,
  removeNonNumericAndSpaces,
  replaceNewlinesWithSpaces,
} from "~/utils";
import { useState } from "react";
import PlatformsToast from "~/components/non-editable/core/patforms-toast";
import useDomain from "~/hooks/useSubdomain";

export const LeadCaptureForm = ({
  ctaProps,
  openLeadCaptureForm,
  setOpenLeadCaptureForm,
}: leadCaptureFormProps) => {
  const inventoryId = ctaProps?.data?.vehicle?.inventoryId ?? null;
  const { domain } = useDomain();

  const [toast, setToast] = useState<{ message: string; type: string } | null>(
    null
  );
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const isVehicleLead = false;

  const whatsappPhone =
    typeof ctaProps.whatsappVendorPhone === "string"
      ? ctaProps.whatsappVendorPhone
      : ctaProps.whatsappVendorPhone?.value;

  const vendorPhone =
    typeof ctaProps.vendorPhone === "string"
      ? ctaProps.vendorPhone
      : ctaProps.vendorPhone?.value;

  const workflowId =
    typeof ctaProps.workflowId === "string"
      ? ctaProps.workflowId
      : ctaProps.workflowId.value;

  const handleModalTitle = () => {
    switch (openLeadCaptureForm) {
      case "whatsapp":
        return "Me chame agora no whatsapp";
      case "call":
        return "Me ligue agora";
      default:
        break;
    }
  };

  const handleButtonIcon = (): string => {
    switch (openLeadCaptureForm) {
      case "whatsapp":
        return "ic:round-whatsapp";
      case "call":
        return "ph:phone";
      default:
        return "";
    }
  };

  const handleButtonText = () => {
    switch (openLeadCaptureForm) {
      case "whatsapp":
        return "Chamar agora";
      case "call":
        return "Ligar agora";
      default:
        return "";
    }
  };

  const handleClose = () => {
    setOpenLeadCaptureForm(null);
  };

  const onSubmit = async (data: any): Promise<void | boolean> => {
    setToast({
      message: "Dados enviados com sucesso! Aguarde nosso contado.",
      type: "success",
    });
    if (openLeadCaptureForm === "whatsapp" && whatsappPhone) {
      const encodedMessage = encodeURIComponent(data.message as string);
      window.open(
        `https://wa.me/55${removeNonNumericAndSpaces(
          String(whatsappPhone)
        )}?text=${encodedMessage}`,
        "_blank"
      );
    }

    if (openLeadCaptureForm === "call" && vendorPhone) {
      window.open(`tel:${vendorPhone}`, "_blank");
    }

    let originUrl;

    if (typeof window !== "undefined") {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port = window.location.port;

      originUrl = `${protocol}//${hostname}${port ? ":" + port : ""}${
        window.location.pathname
      }`;
    }

    const newLead = {
      name: data.name,
      email: data.email,
      cellphone: data.cellphone,
      message: replaceNewlinesWithSpaces(data.message) ?? "",
      originUrl: originUrl,
      workflowId: workflowId,
      sendLeadTo: ctaProps.emailToSendLead.value,
      domain: domain,
      inventoryCode: inventoryId,
      metadata: {},
      isVehicleLead,
    };

    try {
      await fetch(
        `${process.env.BASE_ADDRESS}/api/workflow-basic-contact-form`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newLead),
        }
      );
    } catch (error) {
      console.error(error);
    }

    reset();
    setOpenLeadCaptureForm(null);
    setToast(null);

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(isSubmitting);
      }, 2000);
    });
  };

  return (
    <section className={`${styles.overlay}`}>
      <div className={`${styles.modal}`}>
        <div className={`${styles.modal__header}`}>
          <h5>{handleModalTitle()}</h5>
          <PlatformsIcon
            onClick={handleClose}
            width={20}
            height={20}
            className={`${styles.icon} icon-${ctaProps.id}`}
            icon="ic:round-close"
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            id="name"
            placeholder="Digite seu Nome"
            required
            {...register("name")}
          />
          <input
            type="email"
            id="email"
            placeholder="Digite seu Email"
            required
            {...register("email")}
          />
          <input
            required
            type="tel"
            id="phone"
            placeholder="Digite seu Telefone"
            {...register("cellphone", {
              onChange: (e) => {
                const maskedValue = maskPhone(e.target.value);
                setValue("cellphone", maskedValue);
              },
              maxLength: 15,
            })}
          />
          {openLeadCaptureForm === "whatsapp" && (
            <textarea
              rows={6}
              placeholder="Digite sua mensagem"
              {...register("message")}
            />
          )}

          <button
            type="submit"
            disabled={isSubmitting}
            data-umami-event={
              openLeadCaptureForm === "whatsapp"
                ? "Falar no Whatsapp"
                : "Ligar Agora"
            }
          >
            {!isSubmitting ? (
              <>
                <PlatformsIcon
                  width={25}
                  height={25}
                  className={`${styles.icon} icon-${ctaProps.id}`}
                  icon={handleButtonIcon()}
                />
                {handleButtonText()}
              </>
            ) : (
              <>
                <PlatformsIcon
                  width={25}
                  height={25}
                  className={`${styles.icon__loading} icon-${ctaProps.id}`}
                  icon={"mingcute:loading-3-fill"}
                />
                Aguarde um momento...
              </>
            )}
          </button>
        </form>
      </div>
      {toast && <PlatformsToast message={toast.message} type={toast.type} />}
    </section>
  );
};
