"use client";

import { PlatformsIcon } from "~/components/non-editable/core";
import styles from "./action.module.scss";
import {
  maskPhone,
  removeNonNumericAndSpaces,
  replaceNewlinesWithSpaces,
} from "~/utils";
import { DevelopedBy } from "~/components/editable/developed-by";
import { ActionCallProps } from "./props";
import useDomain from "~/hooks/useSubdomain";
import { useForm } from "react-hook-form";
import { useState } from "react";
import PlatformsToast from "~/components/non-editable/core/patforms-toast";

export const ActionCall = ({
  ctaProps,
  openWhatsappForm,
  setOpenWhatsappForm,
  actionType,
}: ActionCallProps) => {
  const { domain } = useDomain();

  const [toast, setToast] = useState<{ message: string; type: string } | null>(
    null
  );

  const isVehicleLead = false;

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const vendorPhone =
    typeof ctaProps.vendorPhone === "string"
      ? ctaProps.vendorPhone
      : ctaProps.vendorPhone?.value;

  const workflowId =
    typeof ctaProps.workflowId === "string"
      ? ctaProps.workflowId
      : ctaProps.workflowId.value;

  const handleOpenWhatsappForm = () => {
    setOpenWhatsappForm(!openWhatsappForm);
    reset();
  };

  const onSubmit = async (data: any) => {
    setToast({
      message: "Dados enviados com sucesso! Aguarde nosso contado.",
      type: "success",
    });
    const formattedPhone = removeNonNumericAndSpaces(String(vendorPhone));
    const phoneNumber = "+55" + formattedPhone;
    window.open(`tel:${phoneNumber}`, "_blank");

    let originUrl;

    if (typeof window !== "undefined") {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port = window.location.port;

      originUrl = `${protocol}//${hostname}${port ? ":" + port : ""}${
        window.location.pathname
      }`;
    }

    const newLead = {
      name: data.name,
      email: data.email,
      cellphone: data.cellphone,
      message: replaceNewlinesWithSpaces(data.message) ?? "",
      originUrl: originUrl,
      workflowId: workflowId as string,
      sendLeadTo:
        typeof ctaProps.emailToSendLead === "string"
          ? ctaProps.emailToSendLead
          : (ctaProps.emailToSendLead.value as string) ?? ("" as string),
      domain: domain,
      inventoryCode: null,
      metadata: {},
      isVehicleLead,
    };

    try {
      await fetch(
        `${process.env.BASE_ADDRESS}/api/workflow-basic-contact-form`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newLead),
        }
      );
    } catch (error) {
      console.error(error);
    }

    setOpenWhatsappForm(false);
    reset();
    setToast(null);
  };

  const renderContent = () => {
    switch (actionType) {
      case "call":
        return (
          <>
            {typeof ctaProps.whatsappVendorPhone != "undefined" && (
              <>
                <div className={`${styles.whatsapp__form__container}`}>
                  <div className={`${styles.header__container}`}>
                    <button type="button" onClick={handleOpenWhatsappForm}>
                      <PlatformsIcon
                        width={20}
                        height={20}
                        className={`${styles.icon} icon-${ctaProps.id}`}
                        icon="ic:round-close"
                      />
                    </button>
                    <h4>Fale comigo</h4>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`${styles.wrapper}`}>
                      <input
                        required
                        type="text"
                        placeholder="Nome"
                        {...register("name")}
                      />
                      <input
                        required
                        type="email"
                        placeholder="Email"
                        {...register("email")}
                      />
                      <input
                        required
                        type="tel"
                        placeholder="Telefone"
                        {...register("cellphone", {
                          onChange: (e) => {
                            const maskedValue = maskPhone(e.target.value);
                            setValue("cellphone", maskedValue);
                          },
                          maxLength: 15,
                        })}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                          gap: "15px",
                        }}
                      >
                        <input
                          id="check"
                          defaultChecked={true}
                          type="checkbox"
                        />
                        <label htmlFor="check">
                          Ao enviar, você concordo com os termos de uso.
                        </label>
                      </div>
                    </div>

                    <div className={`${styles.footer__container}`}>
                      <button disabled={isSubmitting} type="submit">
                        {!isSubmitting ? (
                          <>
                            <PlatformsIcon
                              width={25}
                              height={25}
                              className={`${styles.icon} icon-${ctaProps.id}`}
                              icon="ph:phone"
                            />
                            Ligar Agora
                          </>
                        ) : (
                          <>
                            <PlatformsIcon
                              width={25}
                              height={25}
                              className={`${styles.icon__loading} icon-${ctaProps.id}`}
                              icon={"mingcute:loading-3-fill"}
                            />
                            Aguarde um momento...
                          </>
                        )}
                      </button>

                      <DevelopedBy />
                    </div>
                  </form>
                </div>
                {toast && (
                  <PlatformsToast message={toast.message} type={toast.type} />
                )}
              </>
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return renderContent();
};
