"use client";

import { useCustomScroll } from "~/hooks/useCustomScroll";
import { StickFooter1Props } from "./props";
import styles from "./sticky-footer.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { useDetectMobile } from "~/hooks/useDetectMobile";
import { handleFormatStyleString, removeNonNumericAndSpaces } from "~/utils";
import { PlatformsIcon } from "~/components/non-editable/core";

export const StickFooter1 = (props: StickFooter1Props) => {
  const { stickyState } = useCustomScroll();
  const { isMobile } = useDetectMobile();

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}
      <AnimatePresence exitBeforeEnter>
        <motion.div
          className={`${styles.container} container-${props.id}`}
          initial={{ height: isMobile ? "80px" : "120px" }}
          //@ts-ignore
          animate={{
            height: stickyState ? (isMobile ? "80px" : "120px") : "0px",
            paddingBlock: !stickyState && "0px",
          }}
          transition={{
            duration: 0.2,
            type: "spring",
            bounce: 300,
            damping: 700,
          }}
          key={`height-animation-${String(stickyState)}`}
        >
          {props && typeof props.vendorEmail != "undefined" && (
            <div
              className={`${styles.grid} grid-${props.id} ${
                String(props.vendorEmail?.value ?? props.vendorEmail) !== ""
                  ? `${styles["grid--3cols"]} grid--3cols-${props.id}`
                  : ""
              }`}
            >
              {typeof props.vendorPhone1 != "undefined" && (
                <div className={`${styles.wrapper} wrapper-${props.id}`}>
                  <a
                    target="_blank"
                    href={
                      "tel:+55" +
                      removeNonNumericAndSpaces(
                        String(props.vendorPhone1?.value ?? props.vendorPhone1)
                      )
                    }
                    className="gtm_event_whatsapp"
                    rel="noreferrer"
                    data-umami-event="Ligar Agora"
                  >
                    <p>Ligar Agora</p>
                    <div className={`${styles.contact} contact-${props.id}`}>
                      <PlatformsIcon
                        width={25}
                        height={25}
                        className={`${styles.icon} icon-${props.id}`}
                        icon="ph:phone"
                      />

                      <p>&nbsp; TELEFONE</p>
                    </div>
                  </a>
                </div>
              )}

              {typeof props.vendorWhatsAppPhone1 != "undefined" && (
                <div
                  style={{
                    borderRight:
                      String(props.vendorEmail?.value ?? props.vendorEmail) !=
                      ""
                        ? "2px solid black"
                        : "",
                  }}
                  className={`${styles.wrapper} wrapper-${props.id}`}
                >
                  <a
                    target="_blank"
                    href={`https://wa.me/55${removeNonNumericAndSpaces(
                      String(
                        props.vendorWhatsAppPhone1?.value ??
                          props.vendorWhatsAppPhone1
                      )
                    )}?text=Oi%2C%20estou%20interessado%20em%20um%20ve%C3%ADculo%20que%20vi%20no%20seu%20site.%20Pode%20me%20fornecer%20mais%20informa%C3%A7%C3%B5es%3F`}
                    className="gtm_event_whatsapp"
                    rel="noreferrer"
                    data-umami-event="Falar no Whatsapp"
                  >
                    Atendimento por <br />
                    <div className={`${styles.contact} contact-${props.id}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        className="inline w-6 h-6"
                        viewBox="0 0 24 24"
                        style={{ fill: "#000" }}
                      >
                        <path d="M 12 2 C 6.5 2 2 6.5 2 12 C 2 13.8 2.5007813 15.5 3.3007812 17 L 2 22 L 7.1992188 20.800781 C 8.6992188 21.600781 10.3 22 12 22 C 17.5 22 22 17.5 22 12 C 22 9.3 20.999609 6.8003906 19.099609 4.9003906 C 17.199609 3.0003906 14.7 2 12 2 z M 12 4 C 14.1 4 16.099219 4.8007813 17.699219 6.3007812 C 19.199219 7.9007813 20 9.9 20 12 C 20 16.4 16.4 20 12 20 C 10.7 20 9.2992188 19.7 8.1992188 19 L 7.5 18.599609 L 6.8007812 18.800781 L 4.8007812 19.300781 L 5.3007812 17.5 L 5.5 16.699219 L 5.0996094 16 C 4.3996094 14.8 4 13.4 4 12 C 4 7.6 7.6 4 12 4 z M 8.5 7.4003906 C 8.3 7.4003906 8.0007812 7.3992188 7.8007812 7.6992188 C 7.5007813 7.9992188 6.9003906 8.6007813 6.9003906 9.8007812 C 6.9003906 11.000781 7.8003906 12.200391 7.9003906 12.400391 C 8.1003906 12.600391 9.6992188 15.199219 12.199219 16.199219 C 14.299219 16.999219 14.699219 16.800781 15.199219 16.800781 C 15.699219 16.700781 16.700391 16.199609 16.900391 15.599609 C 17.100391 14.999609 17.099219 14.499219 17.199219 14.199219 C 17.099219 14.099219 16.999219 14.000391 16.699219 13.900391 C 16.499219 13.800391 15.3 13.199609 15 13.099609 C 14.7 12.999609 14.600391 12.899219 14.400391 13.199219 C 14.200391 13.499219 13.699609 13.999219 13.599609 14.199219 C 13.499609 14.399219 13.399609 14.400781 13.099609 14.300781 C 12.899609 14.200781 12.099609 13.999609 11.099609 13.099609 C 10.299609 12.499609 9.7992187 11.700391 9.6992188 11.400391 C 9.4992187 11.200391 9.7007813 11.000391 9.8007812 10.900391 L 10.199219 10.5 C 10.299219 10.4 10.300391 10.199609 10.400391 10.099609 C 10.500391 9.9996094 10.500391 9.8992188 10.400391 9.6992188 C 10.300391 9.4992187 9.7996094 8.3007812 9.5996094 7.8007812 C 9.3996094 7.4007812 9.2 7.4003906 9 7.4003906 L 8.5 7.4003906 z" />
                      </svg>
                      <p className="font-bold uppercase">&nbsp;WhatsApp</p>
                    </div>
                  </a>
                </div>
              )}

              {typeof props.vendorEmail != "undefined" &&
                String(props.vendorEmail?.value ?? props.vendorEmail) != "" && (
                  <div
                    className={`${styles.wrapper} wrapper-${props.id} ${styles["wrapper--hide"]} wrapper--hide-${props.id}`}
                  >
                    <a
                      target="_link"
                      href={
                        "mailto:" +
                        String(props.vendorEmail?.value ?? props.vendorEmail)
                      }
                    >
                      Atendimento por
                      <div className={`${styles.contact} contact-${props.id}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="inline w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          style={{ fill: "#000" }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p>&nbsp;Email</p>
                      </div>
                    </a>
                  </div>
                )}
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </>
  );
};
