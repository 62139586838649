import Link from "next/link";

import styles from "./card-variant-04.module.scss";
import { PlatformsImage } from "~/components/non-editable/core";
import { VehicleCardVariantProps } from "../props";
import Chip from "~/components/non-editable/misc/chip";

export default function VehicleCardVariant04({
  vehicle,
  priority,
  options,
  adjustHeightBasedOnOptions,
  imageThumb,
  vehiclePrice,
  id,
  formatedSlug,
}: VehicleCardVariantProps) {
  return (
    <Link
      href={formatedSlug}
      className={`${styles.card} card-${id} ${
        adjustHeightBasedOnOptions ? styles["card--adjust-height"] : ""
      } `}
    >
      <PlatformsImage
        width={500}
        height={500}
        layout="responsive"
        src={imageThumb}
        alt={`Imagem principal do veículo`}
        className={`${styles.image} image-${id}`}
        priority={priority}
        loading={priority ? "eager" : "lazy"}
      />
      <div className={styles.content}>
        <h3>
          {(vehicle?.type ?? "")?.toLowerCase() === "car" ? (
            <>
              {vehicle.make}{" "}
              <span className={`${styles.highlight} highlight-${id}`}>
                {vehicle.model}
              </span>
            </>
          ) : (
            <span>{vehicle.make}</span>
          )}
        </h3>
        <div className={`${styles["text-wrapper"]} text-wrapper-${id}`}>
          <p className={`${styles.description} description-${id}`}>
            {(vehicle?.type ?? "")?.toLowerCase() === "car"
              ? vehicle.version
              : vehicle.model}
          </p>

          <div className={`${styles["chip_row"]} chip_row-${id}`}>
            {vehicle?.features
              .split(",")
              ?.filter((_, idx) => idx < 5)
              .map((feat) => (
                <Chip useSmall>{feat}</Chip>
              ))}
          </div>
          {vehiclePrice &&
            Boolean(
              options.showVehiclePrice?.value ?? options.showVehiclePrice
            ) && (
              <div className={`${styles["text-wrapper"]} text-wrapper-${id}`}>
                <h4>
                  <span className={`${styles.price} price-${id}`}>R$</span>{" "}
                  {vehiclePrice}
                </h4>
              </div>
            )}

          <div className={`${styles.row} row-${id}`}>
            {Boolean(
              options.showYearFilter?.value ?? options.showYearFilter
            ) && (
              <p>
                {vehicle.manufactureYear}/{vehicle.modelYear}
              </p>
            )}
            {Boolean(options.showKmFilter?.value ?? options.showKmFilter) && (
              <p>
                {Intl.NumberFormat("pt-BR", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(Number(vehicle.kilometers))}{" "}
                km
              </p>
            )}
          </div>

          {Boolean(
            options.showVehiclePrice?.value ?? options.showVehiclePrice
          ) === false &&
            String(
              options.textAboveVehiclePrice?.value ??
                options.textAboveVehiclePrice
            ) !== "" &&
            options.textAboveVehiclePrice !== undefined && (
              <div className={`${styles["text-wrapper"]} text-wrapper-${id}`}>
                <h4>
                  {String(
                    options.textAboveVehiclePrice?.value ??
                      options.textAboveVehiclePrice
                  )}
                </h4>
              </div>
            )}
        </div>
      </div>
    </Link>
  );
}
