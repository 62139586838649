"use client";

import { usePathname } from "next/navigation";
import { FilterItemProps } from "./props";
import { useEffect, useState } from "react";
import Disclosure from "../disclosure";

import styles from "./slide-item.module.scss";
import { groupBy, handleNormalizeString } from "~/utils";
import { Vehicle } from "~/types";
import { extractVehicleDetailsFromPathname } from "@sync/utils/src/platforms";
import { FilterDefaultQueryKeys } from "../options";

export default function SlideItem({
  itemKey,
  title,
  vehicles,
  queryKey,
  handleFilter,
  useListByKey = false,
  showOption,
}: FilterItemProps) {
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: boolean;
  }>({});
  const [value, setValue] = useState<any>("-");

  const pathname = usePathname();

  useEffect(() => {
    // Extrair os valores da URL usando o método `extractVehicleDetailsFromPathname`
    const details = extractVehicleDetailsFromPathname(pathname as string);
    const filters: { [key: string]: boolean } = {}; // Ajustado para boolean
    if (details) {
      if (details.filter?.minYear && details.filter?.minYear > 0) {
        filters[FilterDefaultQueryKeys.ManufactureYear] = true;
        setValue(details.filter?.minYear);
      }
      if (details.filter?.maxYear && details.filter?.maxYear > 0) {
        filters[FilterDefaultQueryKeys.ModelYear] = true;
        setValue(details.filter?.maxYear);
      }
    }
    setSelectedFilters(filters); // Agora o selectedFilters será atualizado corretamente com valores booleanos
  }, [pathname]);

  const optionsByKey = groupBy(
    vehicles.filter((v) => v[itemKey] != "0" && v[itemKey] != null),
    itemKey
  );
  const make = groupBy(vehicles, "make");

  function generateListByKey() {
    let listByKey;
    const filterItemByKey = Object.keys(make).filter(
      (makeKey) =>
        handleNormalizeString(makeKey) ===
        (selectedFilters[FilterDefaultQueryKeys.Make] ? "true" : "false")
    );

    if (filterItemByKey.length > 0 && vehicles) {
      listByKey = groupBy(
        vehicles.filter((veic: Vehicle) =>
          filterItemByKey.some(
            (item: string) => handleNormalizeString(veic.make) === item
          )
        ),
        "category"
      );
    } else {
      listByKey = optionsByKey;
    }
    return listByKey;
  }
  const options = useListByKey ? generateListByKey() : optionsByKey;

  const minValue = Object.keys(options)?.[0] ?? "";
  const maxValue =
    Object.keys(options)?.[Object.keys(options).length - 1] ?? "";

  if (!showOption) {
    return null;
  }

  return (
    <Disclosure defaultOpen={true} childrenCount={4} title={title}>
      <div className={styles.container}>
        <span className={styles.chip}>{value}</span>
        <input
          id="labels-range-input"
          type="range"
          onChange={(e) => {
            setValue(e.target.value);
            handleFilter(e, e.target.value, queryKey);
          }}
          value={value}
          min={minValue?.toString()}
          max={maxValue?.toString()}
          className="!bg-primary-100"
        />
        <span className={`${styles["year"]} year ${styles["year--left"]}`}>
          {minValue}
        </span>
        <span className={`${styles["year"]} year ${styles["year--right"]}`}>
          {maxValue}
        </span>
      </div>
    </Disclosure>
  );
}
