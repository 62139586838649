import Link from "next/link";

import styles from "./card-variant-02.module.scss";
import { PlatformsImage } from "~/components/non-editable/core";
import { VehicleCardVariantProps } from "../props";

export default function VehicleCardVariant02({
  vehicle,
  priority,
  options,
  adjustHeightBasedOnOptions,
  imageThumb,
  id,
  integradorId,
  vehiclePrice,
  formatedSlug,
}: VehicleCardVariantProps) {
  return (
    <Link href={formatedSlug}>
      <div
        key={integradorId}
        className={`${styles.card} card-${id} ${
          adjustHeightBasedOnOptions ? styles["card--adjust-height"] : ""
        } `}
      >
        <div className="relative">
          <PlatformsImage
            width={278}
            height={208}
            layout="responsive"
            src={imageThumb}
            alt={`Imagem principal do veículo`}
            className={`${styles.image} image-${id}`}
            priority={priority}
            loading={priority ? "eager" : "lazy"}
          />
        </div>
        <div className={`${styles.content} content-${id}`}>
          <h3>
            {(vehicle?.type ?? "")?.toLowerCase() === "car" ? (
              <>
                {vehicle.make}{" "}
                <span className={`${styles.highlight} hightlight-${id}`}>
                  {vehicle.model}
                </span>
              </>
            ) : (
              <span>{vehicle.make}</span>
            )}
          </h3>
          <div className={`${styles["text-wrapper"]} text-wrapper-${id}`}>
            <p className={`${styles.description} description-${id}`}>
              {(vehicle?.type ?? "")?.toLowerCase() === "car"
                ? vehicle.version
                : vehicle.model}
            </p>

            {vehiclePrice &&
              Boolean(
                options.showVehiclePrice?.value ?? options.showVehiclePrice
              ) && (
                <div className={`${styles["text-wrapper"]} text-wrapper-${id}`}>
                  <h4>
                    <span className={styles.price}>R$</span> {vehiclePrice}
                  </h4>
                </div>
              )}

            <div className={`${styles.row} row-${id}`}>
              {Boolean(
                options.showYearFilter?.value ?? options.showYearFilter
              ) && (
                <p>
                  {vehicle.manufactureYear}/{vehicle.modelYear}
                </p>
              )}

              {Boolean(options.showKmFilter?.value ?? options.showKmFilter) && (
                <p>
                  {Intl.NumberFormat("pt-BR", {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(Number(vehicle.kilometers))}{" "}
                  km
                </p>
              )}
            </div>

            {Boolean(
              options.showVehiclePrice?.value ?? options.showVehiclePrice
            ) === false &&
              String(
                options.textAboveVehiclePrice?.value ??
                  options.textAboveVehiclePrice
              ) !== "" &&
              options.textAboveVehiclePrice !== undefined && (
                <div className={`${styles["text-wrapper"]} text-wrapper-${id}`}>
                  <h4>
                    {String(
                      options.textAboveVehiclePrice?.value ??
                        options.textAboveVehiclePrice
                    )}
                  </h4>
                </div>
              )}
          </div>
        </div>
      </div>
    </Link>
  );
}
