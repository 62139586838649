"use client";

import { Cta1Props } from "./props";
import styles from "./cta1.module.scss";
import { handleFormatStyleString } from "~/utils";

import { PlatformsIcon, PlatformsImage } from "~/components/non-editable/core";
import { useState } from "react";
import { MobileMenu } from "./mobile-menu";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { LeadCaptureForm } from "./lead-capture-form";

export function Cta1(props: Cta1Props): JSX.Element {
  const pathname = usePathname();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openLeadCaptureForm, setOpenLeadCaptureForm] = useState<
    "whatsapp" | "call" | null
  >(null);

  const hasVehicle =
    pathname?.includes(":") && Object.keys(props.data).length === 0
      ? false
      : true;

  const handleOpenMenu = () => {
    setOpenMenu((prev) => !prev);
  };

  if (!hasVehicle) {
    return <></>;
  }

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}
      <section className={`${styles.container} container-${props.id}`}>
        <div className={`${styles.wrapper} wrapper-${props.id}`}>
          {typeof props?.image != "undefined" && (
            <div
              className={`${styles["image-container"]} image-container-${props.id}`}
            >
              <Link
                href="/"
                className={`${styles["image-wrapper"]} image-wrapper-${props.id}`}
                rel="noreferer"
              >
                <PlatformsImage
                  width={385}
                  height={288}
                  priority
                  className={`${styles.image} image-${props.id}`}
                  src={(props?.image?.value ?? props?.image) as string}
                  alt=""
                />
              </Link>

              <Link
                href="/"
                rel="noreferer"
                className={`${styles["vendor-name-container"]} vendor-name-container${props.id}`}
              >
                <>
                  {props && typeof props.vendorName != "undefined" && (
                    <h1>
                      {typeof props.vendorName === "string"
                        ? props.vendorName
                        : props.vendorName.value}
                    </h1>
                  )}
                  {props && typeof props.shortDescription != "undefined" && (
                    <span>
                      {typeof props.shortDescription === "string"
                        ? props.shortDescription
                        : props.shortDescription.value}
                    </span>
                  )}
                </>
              </Link>
            </div>
          )}
          <div className={`${styles["text-wrapper"]} text-wrapper-${props.id}`}>
            {typeof props.highlight != "undefined" && (
              <h2
                data-highlight={String(
                  props.highlight?.value ?? props.highlight
                )}
              >
                {String(props.heading?.value ?? props.heading)}
              </h2>
            )}
            {typeof props.description != "undefined" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: String(props.description?.value ?? props.description),
                }}
                className={`${styles.description} description-${props.id}`}
              />
            )}
            {typeof props.action != "undefined" && (
              <div
                className={`${styles["button-container"]} button-container-${props.id}`}
              >
                <button
                  onClick={() => setOpenLeadCaptureForm("call")}
                  className={`${styles.button} button-${props.id}`}
                >
                  <PlatformsIcon
                    width={25}
                    height={25}
                    className={`${styles.icon} icon-${props.id}`}
                    icon="ph:phone"
                  />
                  Ligar Agora
                </button>

                <button
                  className={`${styles.button} button-${props.id} gtm_event_whatsapp`}
                  onClick={() => setOpenLeadCaptureForm("whatsapp")}
                >
                  <PlatformsIcon
                    width={25}
                    height={25}
                    className={`${styles.icon} icon-${props.id}`}
                    icon="ic:round-whatsapp"
                  />
                  Chamar no Whatsapp
                </button>
              </div>
            )}
          </div>
          <div className="px-[10px]">
            {!openMenu ? (
              <PlatformsIcon
                width={20}
                height={20}
                className={`${styles["menu-icon"]} menu-icon-${props.id}`}
                icon="lucide:menu"
                onClick={handleOpenMenu}
              />
            ) : (
              <PlatformsIcon
                width={20}
                height={20}
                className={`${styles["menu-icon"]} menu-icon-${props.id}`}
                icon="mingcute:close-line"
                onClick={handleOpenMenu}
              />
            )}
          </div>
        </div>
      </section>
      <MobileMenu ctaProps={props} open={openMenu} setOpen={setOpenMenu} />
      {openLeadCaptureForm != null && (
        <LeadCaptureForm
          ctaProps={props}
          openLeadCaptureForm={openLeadCaptureForm}
          setOpenLeadCaptureForm={setOpenLeadCaptureForm}
        />
      )}

      {/* <div style={{ position: "relative", height: "100vh", overflow: "auto" }}>
        <iframe
          src="https://survey.sync.com.br/s/cm0cjyzkj001vosnj3d72jyzd"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: 0,
          }}
        />
      </div> */}
    </>
  );
}
