import { handleFormatStyleString } from "~/utils";
import { DetailsVariantsProps } from "../props";
import { VehicleDetailsVariant1 } from "./01";
import { VehicleDetailsVariant2 } from "./02";
import { SendGTMEvents } from "../send-gtm-events";

export default function DetailsVariants({
  props,
  vehicle,
  vehiclePrice,
  variant,
}: DetailsVariantsProps) {
  function renderDetails() {
    switch (variant) {
      case 1:
        return (
          <VehicleDetailsVariant1
            props={props}
            variant={variant}
            vehicle={vehicle}
            vehiclePrice={vehiclePrice}
            {...props}
          />
        );
      case 2:
        return (
          <VehicleDetailsVariant2
            props={props}
            variant={variant}
            vehicle={vehicle}
            vehiclePrice={vehiclePrice}
            {...props}
          />
        );
      default:
        return (
          <VehicleDetailsVariant1
            props={props}
            variant={variant}
            vehicle={vehicle}
            vehiclePrice={vehiclePrice}
            {...props}
          />
        );
    }
  }

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}

      <SendGTMEvents vehicle={vehicle} />

      {renderDetails()}
    </>
  );
}
