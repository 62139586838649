"use client";

import PlatformsButton from "~/components/non-editable/core/platforms-button";
import { Hero1Props } from "../../props";
import styles from "./hero-variant-04.module.scss";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { Vehicle } from "~/types";
import { vehicleLinkBuilderByReference } from "~/utils";
import { PlatformsIcon } from "~/components/non-editable/core";
import { fadeInDownStaggered, staggeredChildren } from "~/styles/variants";
import { AnimatePresence, motion } from "framer-motion";

export default function HeroVariant04(props: Hero1Props): JSX.Element {
  const { push } = useRouter();
  const [search, setSearch] = useState("");
  const [displayOptions, setDisplayOptions] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [selectedVehicleId, setSelectedVehicleId] = useState<string>("");

  const searchButtonRef = useRef<HTMLButtonElement | null>(null);

  const vehicles = props?.data?.vehicleList ?? [];

  const site = props.data.site;

  const init = () => {
    if (vehicles) {
      const orderedOptions = vehicles.sort((a: Vehicle, b: Vehicle) => {
        const nameA = a?.make?.toString()?.toLowerCase() ?? "";
        const nameB = b?.make?.toString()?.toLowerCase() ?? "";
        return nameA?.localeCompare(nameB);
      });
      setOptions(orderedOptions);
    }
  };

  useEffect(init, []);

  const handleSearch = () => {
    if (search === "") {
      setDisplayOptions(false);
    }
  };
  useEffect(handleSearch, [search]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayOptions(true);
    setSearch(e?.target?.value);
  };

  const handleOptionClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    const target = e?.target as HTMLLIElement;
    setSearch(target?.innerText);
    setDisplayOptions(false);

    if (searchButtonRef?.current) {
      searchButtonRef?.current?.focus();
    }

    setSelectedVehicleId(e.currentTarget.id);
  };

  const handleButtonClick = () => {
    setDisplayOptions(false);
    const vehicle = vehicles.find(
      (v) => v.id === selectedVehicleId || v.externalId === selectedVehicleId
    );

    const vehicleUrl =
      vehicleLinkBuilderByReference({
        site: {
          subdomain: site?.subdomain as string,
          customDomain: site?.customDomain ?? "",
          preferences: site?.preferences ?? [],
        },
        vehicle,
      }) ?? "/";

    push(vehicleUrl);
    push(vehicleUrl);
  };
  return (
    <div
      className={`${styles.container} container-${props.id}`}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${
          typeof props.image != "undefined"
            ? String(props?.image?.value ?? props?.image).replace("blob:", "")
            : ""
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div
        className={`${styles["container__wrapper"]} container__wrapper-${props.id}`}
      >
        {(props?.heading?.value ?? props?.heading) && (
          <h1
            className={`${styles["container__wrapper__title"]} container__wrapper__title-${props.id}`}
          >
            {String(props?.heading?.value ?? props?.heading)}
          </h1>
        )}
        {(props?.description?.value ?? props?.description) && (
          <h3
            className={`${styles["container__wrapper__description"]} container__wrapper__description-${props.id}`}
          >
            {String(props?.description?.value ?? props?.description)}
          </h3>
        )}
        {props?.action && (
          <div
            className={`${styles["container__wrapper__row"]} container__wrapper__row-${props.id}`}
          >
            {/* @ts-ignore */}
            <PlatformsButton action={props.action} componentId={props.id} />
          </div>
        )}
      </div>
      <div
        className={`${styles["container__wrapper__card"]} container__wrapper__card-${props.id}`}
      >
        <div
          className={`${styles["container__wrapper__card__wrapper"]} container__wrapper__card__wrapper-${props.id}`}
        >
          <input
            className={`${styles["container__wrapper__card__wrapper__input"]} input-${props.id}`}
            placeholder="Busque por marca ou modelo"
            type="text"
            value={search}
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleButtonClick();
              }
            }}
          />
          <button
            className={`${styles["container__wrapper__card__button"]} container__wrapper__card__button-${props.id}`}
            type="button"
            onClick={handleButtonClick}
            name="Search button"
            aria-label="Search button"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleButtonClick();
              }
            }}
            ref={searchButtonRef}
          >
            Pesquisar
            <PlatformsIcon
              className="icon"
              icon="ic:baseline-search"
              width={30}
              height={30}
            />
          </button>
          <AnimatePresence key={String(displayOptions)} exitBeforeEnter>
            {displayOptions && (
              <motion.ul
                className={`${styles["list-container"]} list-container-${props.id}`}
                variants={fadeInDownStaggered}
                initial="hidden"
                animate="show"
                key={String(displayOptions)}
              >
                {options &&
                  options
                    ?.filter(
                      (item) =>
                        item.model
                          ?.toLowerCase()
                          .includes(search?.toLowerCase()) ||
                        item.make
                          ?.toLowerCase()
                          .includes(search?.toLowerCase()) ||
                        item.version
                          ?.toLowerCase()
                          .includes(search?.toLowerCase())
                    )
                    .map((item, index) => (
                      <motion.li
                        className={`${styles["list-item"]} list-item-${props.id}`}
                        variants={staggeredChildren}
                        onClick={(e) => {
                          setDisplayOptions(false);
                          handleOptionClick(e);
                          setSelectedVehicleId(e.currentTarget.id);
                        }}
                        key={item + index}
                        id={item.externalId}
                      >
                        <p>{`${item.make} - ${item.model} - ${
                          item.version ? item.version : ""
                        } - ${item.modelYear}`}</p>
                      </motion.li>
                    ))}
              </motion.ul>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
