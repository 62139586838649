"use client";

import { handleFormatStyleString } from "~/utils";
import { Hero1Props } from "./props";
import { HeroVariant01 } from "./varitants/01";
import HeroVariant02 from "./varitants/02";
import HeroVariant03 from "./varitants/03";
import HeroVariant04 from "./varitants/04";

export function Hero1(props: Hero1Props): JSX.Element {
  const variant = Number(props?.variants?.value);

  function renderHero() {
    switch (variant) {
      case 1:
        return <HeroVariant01 {...props} />;
      case 2:
        return <HeroVariant02 {...props} />;
      case 3:
        return <HeroVariant03 {...props} />;
      case 4:
        return <HeroVariant04 {...props} />;
      default:
        return <HeroVariant01 {...props} />;
    }
  }

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}
      {renderHero()}
    </>
  );
}
