"use client";

import { PlatformsIcon } from "~/components/non-editable/core";
import { Cta1Props } from "../../props";
import styles from "../mobile-menu.module.scss";
import { motion } from "framer-motion";

export const ShareButtonMobileMenu = ({
  openWhatsappForm,
  ctaProps,
}: {
  openWhatsappForm: boolean;
  ctaProps: Cta1Props;
}) => {
  const host = window.location.host;
  const protocol = window.location.protocol;

  async function handleNavigatorShare() {
    const url = `${protocol}//${host}`;
    await navigator.share({ url: url });
  }

  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{
        opacity: openWhatsappForm ? 0 : 1,
      }}
      transition={{ duration: openWhatsappForm ? 0.2 : 0.5 }}
      style={{ width: "100%" }}
    >
      <motion.button
        className={`${styles.button} button-${ctaProps.id}`}
        onClick={handleNavigatorShare}
        initial={{
          opacity: 1,
          height: "",
        }}
        animate={{
          opacity: openWhatsappForm ? 0 : 1,
          height: openWhatsappForm ? "1px" : "",
          overflow: openWhatsappForm ? "hidden" : "visible",
        }}
        transition={{ duration: !openWhatsappForm ? 0.2 : 0.5 }}
        style={{ width: "100%" }}
      >
        <PlatformsIcon
          width={25}
          height={25}
          className={`${styles.icon} icon-${ctaProps.id}`}
          icon="iconamoon:share-1-light"
        />
        Compartilhar meu Estoque
      </motion.button>
    </motion.div>
  );
};
