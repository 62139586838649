import { PlatformsIcon } from "~/components/non-editable/core";
import styles from "./qr-code-action.module.scss";
import { ActionProps } from "./props";
import Image from "next/image";
import { DevelopedBy } from "~/components/editable/developed-by";

export const QrCodeAction = ({
  ctaProps,
  openWhatsappForm,
  setOpenWhatsappForm,
}: ActionProps) => {
  const handleOpenWhatsappForm = () => {
    setOpenWhatsappForm(!openWhatsappForm);
  };
  const src =
    typeof ctaProps.qrCodeImage === "string"
      ? (ctaProps.qrCodeImage as string)
      : (ctaProps.qrCodeImage.value as string);

  return (
    <>
      {typeof ctaProps.qrCodeImage != "undefined" && (
        <>
          <div className={`${styles.whatsapp__form__container}`}>
            <div className={`${styles.header__container}`}>
              <button type="button" onClick={handleOpenWhatsappForm}>
                <PlatformsIcon
                  width={20}
                  height={20}
                  className={`${styles.icon} icon-${ctaProps.id}`}
                  icon="ic:round-close"
                />
              </button>
            </div>
            <div className={`${styles.qr__code__container}`}>
              <span className={`${styles.qr__code__description}`}>
                Adicionar na agenda
              </span>
              <Image
                className={`${styles.qr__code__image}`}
                src={src}
                width={500}
                height={500}
                alt="QR Code do vendedor"
              />
            </div>
            <DevelopedBy marginTop="100px" />
          </div>
        </>
      )}
    </>
  );
};
