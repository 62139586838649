import { Vehicle } from "../../../../types";

export enum VehicleGalleryVariant {
  Default = "default",
  Beehive = "beehive",
  Grid = "grid",
}

export interface VehicleGalleryProps {
  vehicle: Vehicle;
  variant: VehicleGalleryVariant;
}
