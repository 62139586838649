import { PlatformsLogoProps } from "./props";

import styles from "./logo.module.scss";
import Link from "next/link";
import { PlatformsImage } from "~/components/non-editable/core";

export default function PlatformsLogo(props: PlatformsLogoProps) {
  return (
    <Link
      aria-label="Logo for homepage"
      href="/"
      className={`${styles["image-wrapper"]} image-wrapper-${props.id}`}
    >
      <>
        {typeof props?.logo != "undefined" && (
          <PlatformsImage
            width={Number(props?.logoWidth?.value ?? props?.logoWidth ?? 100)}
            height={Number(
              props?.logoHeight?.value ?? props?.logoHeight ?? 100
            )}
            src={String(
              props?.logo?.value ??
                props?.logo ??
                "https://sync-platforms.s3.sa-east-1.amazonaws.com/logo-default.svg"
            )}
            className={`${styles.image} image-${props.id} flex dark:hidden`}
            alt="Logo"
          />
        )}

        {typeof props?.logoDark != "undefined" && (
          <PlatformsImage
            width={Number(props?.logoWidth?.value ?? props?.logoWidth ?? 100)}
            height={Number(
              props?.logoHeight?.value ?? props?.logoHeight ?? 100
            )}
            src={String(
              props?.logoDark?.value ??
                props?.logoDark ??
                "https://sync-platforms.s3.sa-east-1.amazonaws.com/logo-default.svg"
            )}
            className={`${styles.image} image-${props.id} hidden dark:flex`}
            alt="Logo"
          />
        )}
      </>
    </Link>
  );
}
