"use client";

import { Suspense, useState } from "react";
import { VehicleGalleryProps, VehicleGalleryVariant } from "./props";
import FsLightbox from "fslightbox-react";
import styles from "./vehicle-gallery.module.scss";
import { resetSyncCdnImageSize } from "~/utils";
import dynamic from "next/dynamic";

const GridVariant = dynamic(() => import("./grid"), { ssr: false }) as any;
const BeehiveVariant = dynamic(() => import("./beehive"), {
  ssr: false,
}) as any;
const DefaultVariant = dynamic(() => import("./default"), {
  ssr: false,
}) as any;

export default function VehicleGallery({
  variant,
  vehicle,
}: VehicleGalleryProps): JSX.Element {
  const vehicleImagesThumb = resetSyncCdnImageSize(
    vehicle.images?.split(","),
    "650",
    "500"
  );
  const vehicleImagesLarge = resetSyncCdnImageSize(
    vehicle.images?.split(","),
    "1440",
    "1080"
  );
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  function renderContent() {
    switch (variant) {
      case VehicleGalleryVariant.Default:
        return (
          <DefaultVariant
            openLightboxOnSlide={openLightboxOnSlide}
            vehicle={vehicle}
            vehicleImagesThumb={vehicleImagesThumb}
          />
        );
      case VehicleGalleryVariant.Beehive:
        return (
          <BeehiveVariant
            openLightboxOnSlide={openLightboxOnSlide}
            vehicle={vehicle}
            vehicleImagesThumb={vehicleImagesThumb}
          />
        );
      case VehicleGalleryVariant.Grid:
        return (
          <GridVariant
            openLightboxOnSlide={openLightboxOnSlide}
            vehicle={vehicle}
            vehicleImagesThumb={vehicleImagesThumb}
          />
        );
      default:
        return (
          <DefaultVariant
            openLightboxOnSlide={openLightboxOnSlide}
            vehicle={vehicle}
            vehicleImagesThumb={vehicleImagesThumb}
          />
        );
    }
  }

  return (
    <Suspense fallback={null}>
      {/* @ts-ignore */}
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={vehicleImagesLarge.map((x) => x)}
        slide={lightboxController.slide}
        type="image"
      />
      <div className={styles.container}>{renderContent()}</div>
    </Suspense>
  );
}
