"use client";

import { PropsWithChildren } from "react";
import PlatformsThemeProviderClient from "./PlatformsThemeProviderClient";
//@ts-ignore
import { GoogleTagManager } from "@next/third-parties/google";

export interface PlatformsThemeProviderProps extends PropsWithChildren {
  settings: any;
}

export function PlatformsThemeProvider({
  children,
  settings,
}: PlatformsThemeProviderProps): JSX.Element {
  const favicon = settings?.theme?.icons?.default?.[0];

  const GTM_ID1 = settings?.theme?.googleTagManagerId01;
  const GTM_ID2 = settings?.theme?.googleTagManagerId02;
  const GTM_ID3 = settings?.theme?.googleTagManagerId03;
  const GTM_ID4 = settings?.theme?.googleTagManagerId04 ?? "";

  return (
    <>
      {favicon != "" && (
        <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
      )}
      {typeof window !== "undefined" &&
        process.env.NODE_ENV == "production" && (
          <>
            {GTM_ID1 != "" && typeof window !== "undefined" && (
              <GoogleTagManager gtmId={GTM_ID1} />
            )}

            {GTM_ID2 != "" && typeof window !== "undefined" && (
              <GoogleTagManager gtmId={GTM_ID2} />
            )}

            {GTM_ID3 != "" && typeof window !== "undefined" && (
              <GoogleTagManager gtmId={GTM_ID3} />
            )}
            {GTM_ID4 != "" && typeof window !== "undefined" && (
              <GoogleTagManager gtmId={GTM_ID4} />
            )}
          </>
        )}
      <PlatformsThemeProviderClient settings={settings} />
      {children}
    </>
  );
}
