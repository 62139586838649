"use client";

import {
  extractRegionFromCurrentSlug,
  resetSyncCdnImageSize,
  vehicleLinkBuilderByReference,
} from "@sync/platforms/src/index";
import VehicleCardVariant01 from "./variants/01";
import { VehicleCardProps } from "./props";
import VehicleCardVariant02 from "./variants/02";
import VehicleCardVariant03 from "./variants/03";
import VehicleCardVariant04 from "./variants/04";
import { Suspense } from "react";
import VehicleCardSkeleton from "./skeleton";

export default function VehicleCard({
  vehicle,
  id,
  priority,
  options,
  variant,
  site,
  currentSlug,
}: VehicleCardProps) {
  const integradorId = vehicle.id ? vehicle.id : vehicle.externalId;
  const imageThumb = resetSyncCdnImageSize(
    [vehicle.images?.split(",")[0]],
    "652",
    "488"
  )[0];

  const region = extractRegionFromCurrentSlug(currentSlug);

  const slug =
    vehicleLinkBuilderByReference({
      vehicle,
      site: {
        subdomain: site?.subdomain as string,
        customDomain: site?.customDomain ?? "",
        preferences: site?.preferences ?? [],
      },
      region,
    }) ?? "/";

  function handleVehiclePrice() {
    let price = +vehicle.price;

    if (vehicle.price === 0) return null;

    if (
      Number(
        options.increaseVehiclePriceAmount?.value ??
          options.increaseVehiclePriceAmount
      ) !== 0
    ) {
      price += Number(
        options.increaseVehiclePriceAmount?.value ??
          options.increaseVehiclePriceAmount
      );
    }

    if (
      Number(
        options.increaseVehiclePriceInPercentage?.value ??
          options.increaseVehiclePriceInPercentage
      ) !== 0
    ) {
      price =
        price +
        price *
          (Number(
            options.increaseVehiclePriceInPercentage?.value ??
              options.increaseVehiclePriceInPercentage
          ) /
            100);
    }

    return price.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const adjustHeightBasedOnOptions =
    options.showVehiclePrice &&
    (String(
      options.textAboveVehiclePrice?.value ?? options.textAboveVehiclePrice
    ) === "" ||
      options.textAboveVehiclePrice === undefined);

  function renderCard() {
    switch (variant) {
      case 1:
        return (
          <VehicleCardVariant01
            id={id}
            options={options}
            priority={priority}
            vehicle={vehicle}
            adjustHeightBasedOnOptions={adjustHeightBasedOnOptions}
            imageThumb={imageThumb}
            integradorId={String(integradorId)}
            formatedSlug={slug}
            slug={slug}
            vehiclePrice={handleVehiclePrice()}
            site={site}
          />
        );
      case 2:
        return (
          <VehicleCardVariant02
            id={id}
            options={options}
            priority={priority}
            vehicle={vehicle}
            adjustHeightBasedOnOptions={adjustHeightBasedOnOptions}
            imageThumb={imageThumb}
            integradorId={String(integradorId)}
            formatedSlug={slug}
            slug={slug}
            vehiclePrice={handleVehiclePrice()}
            site={site}
          />
        );
      case 3:
        return (
          <VehicleCardVariant03
            id={id}
            options={options}
            priority={priority}
            vehicle={vehicle}
            adjustHeightBasedOnOptions={adjustHeightBasedOnOptions}
            imageThumb={imageThumb}
            integradorId={String(integradorId)}
            formatedSlug={slug}
            slug={slug}
            vehiclePrice={handleVehiclePrice()}
            site={site}
          />
        );
      case 4:
        return (
          <VehicleCardVariant04
            id={id}
            options={options}
            priority={priority}
            vehicle={vehicle}
            adjustHeightBasedOnOptions={adjustHeightBasedOnOptions}
            imageThumb={imageThumb}
            integradorId={String(integradorId)}
            formatedSlug={slug}
            slug={slug}
            vehiclePrice={handleVehiclePrice()}
            site={site}
          />
        );
      default:
        return (
          <VehicleCardVariant01
            id={id}
            options={options}
            priority={priority}
            vehicle={vehicle}
            formatedSlug={slug}
            adjustHeightBasedOnOptions={adjustHeightBasedOnOptions}
            imageThumb={imageThumb}
            integradorId={String(integradorId)}
            slug={slug}
            vehiclePrice={handleVehiclePrice()}
            site={site}
          />
        );
    }
  }

  return <Suspense fallback={<VehicleCardSkeleton />}>{renderCard()}</Suspense>;
}
